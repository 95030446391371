const attachmentKeys = {
    surname: 'Surname',
    otherNames: 'Other Names',
    age: 'Age',
    unitNo: 'Unit No',
    date: 'Date',
    physician: 'Physician',
    RX: 'RX',
    injection: 'Injection',
    text: 'Text',

    name: 'Name',
    telephone: 'Telephone',
    ecgNo: 'ECG No',
    echoNo: 'ECHO No',
    birthDay: 'Birth Day',
    sex: 'Sex',
    religion: 'Religion',
    occupation: 'Occupation',
    maritalStatus: 'Marital Status',
    stateOfOrigin: 'State Of Origin',
    clinicalInformation: 'Clinical Information',
    indicationForTest: 'Indication For Test',
    typeOfTest: 'Type Of Test',
    height: 'Height',
    weight: 'Weight',
    BMI: 'BMI',
    BPRight: 'BP Right',
    BPLeft: 'BP Left',

    customer: 'Customer',
    attendingOfficer: 'Attending Officer',
    phone: 'Phone',
    amount: 'Amount',
    paid: 'Paid',
    discountRate: 'Discount Rate',
    discountedAmount: 'Discounted Amount',
    amountToPay: 'Amount to pay',
    amountReceived: 'Amount received',
    receiptNo: 'Receipt No',
    description: 'Description',

    patientAge: 'Patient Age',
    patientSex: 'Patient Sex',
    patientName: 'Patient Name',
    patientAddress: 'Patient Address',
    patientNHIS: 'Patient NHIS',
    patientTel: 'Patient Telephone',
    patientHMONo: 'Patient HMO No',
    RX: 'RX',
    noOfItemsDispensed: 'No. Of Items Dispensed',
    valueOfItemsDispensed: 'Value Of Items Dispensed',
    noOfItemsDispansed: 'No. Of Items Dispensed',
    valueOfItemsDispansed: 'Value Of Items Dispensed',
    patientsEndorsement: 'Patients Endorsement',
    prescribersName: 'Prescriber\'s Name',

    firstName: 'First Name',
    patientNumber: 'Patient Number',
    bloodPressure: 'Blood Pressure',
    temperature: 'Temperature',
    pulseRate: 'Pulse Rate',
    respiratoryRate: 'Respiratory Rate',
    PC02: 'PC02',
    tests: 'Requested Tests',

    ovaOf: 'Ova of',
    trophozoites: 'Trophozoites',
    larvaeOf: 'Larvae Of',
    cells: 'Cells',
    occultBlood: 'Occult Blood',
    dateOfCollection: 'Date of Collection',
    diagnosis: 'Diagnosis',
    LabRef: 'Lab Ref',
    labRef: 'Lab Ref',
    doctor: 'Doctor',
    LabComment: 'Lab Comment',
    malariaParasite: 'Malaria Parasite',
    ChemicalDiagnosis: 'Chemical Diagnosis',

    topic: 'Topic',
    investigationRequired: 'Investigation Required',
    natureOfSpecimen: 'Nature Of Specimen',
    amplitudeOfAccomodation: 'Amplitude Of Accomodation',
    height: 'Height',

    stereopsis: 'Stereopsis',
    distanceRe: 'DistanceRe',
    distanceLe: 'DistanceLe',
    distanceBe: 'DistanceBe',
    nearRe: 'Near RE',
    nearLe: 'Near LE',
    nearBe: 'Near BE',
    pinholeRe: 'Pinhole Ee',
    pinholeLe: 'Pinhole LE',
    pinholeBe: 'Pinhole BE',
}

export default attachmentKeys