export default [
    { label: 'Day1 morning', value: 'Day1 morning' },
    { label: 'Day1 evening', value: 'Day1 evening' },
    { label: 'Day2 morning', value: 'Day2 morning' },
    { label: 'Day2 evening', value: 'Day2 evening' },
    { label: 'Day3 morning', value: 'Day3 morning' },
    { label: 'Day3 evening', value: 'Day3 evening' },
    { label: 'Day4 morning', value: 'Day4 morning' },
    { label: 'Day4 evening', value: 'Day4 evening' },
    { label: 'Day5 morning', value: 'Day5 morning' },
    { label: 'Day5 evening', value: 'Day5 evening' },
    { label: 'Day6 morning', value: 'Day6 morning' },
    { label: 'Day6 evening', value: 'Day6 evening' },
    { label: 'Day7 morning', value: 'Day7 morning' },
    { label: 'Day7 evening', value: 'Day7 evening' },
    { label: 'Day8 morning', value: 'Day8 morning' },
    { label: 'Day8 evening', value: 'Day8 evening' },
    { label: 'Day9 morning', value: 'Day9 morning' },
    { label: 'Day9 evening', value: 'Day9 evening' },
    { label: 'Day10 morning', value: 'Day10 morning' },
    { label: 'Day10 evening', value: 'Day10 evening' },
]