export default [
    { label: 'Tab metronidazole 200mg & 400mg', value: 'Tab metronidazole 200mg & 400mg' },
    { label: 'Tab Hyoscine 10mg', value: 'Tab Hyoscine 10mg' },
    { label: 'Tab Vitamin 100mg', value: 'Tab Vitamin 100mg' },
    { label: 'Tab Amlodipine 10mg', value: 'Tab Amlodipine 10mg' },
    { label: 'Tab Lisinopril 10mg', value: 'Tab Lisinopril 10mg' },
    { label: 'Tab Lisinopril 5mg', value: 'Tab Lisinopril 5mg' },
    { label: 'Tab Loperamide 2mg', value: 'Tab Loperamide 2mg' },
    { label: 'Cap Amoxicillin 500mg', value: 'Cap Amoxicillin 500mg' },
    { label: 'Cap Ampiclox 500mg', value: 'Cap Ampiclox 500mg' },
    { label: 'Tab Rosuvastatin 10mg', value: 'Tab Rosuvastatin 10mg' },
    { label: 'Tab Atorvastatin 10mg', value: 'Tab Atorvastatin 10mg' },
    { label: 'Tab Hydrochorthiazide 25mg', value: 'Tab Hydrochorthiazide 25mg' },
    { label: 'Tab Vasoprin 75mg', value: 'Tab Vasoprin 75mg' },
    { label: 'Tab Paracetamol 500mg', value: 'Tab Paracetamol 500mg' },
    { label: 'Tab Vitamin Bco', value: 'Tab Vitamin Bco' },
    { label: 'Tab Multivite', value: 'Tab Multivite' },
    { label: 'Tab Folic Acid 5mg', value: 'Tab Folic Acid 5mg' },
    { label: 'Tab Ferrous sulphate', value: 'Tab Ferrous sulphate' },
    { label: 'Tab Calcium Lactate 100mg', value: 'Tab Calcium Lactate 100mg' },
    { label: '300mg', value: '300mg' },
    { label: 'Tab Arthemether Lumenfantrine 20/120mg', value: 'Tab Arthemether Lumenfantrine 20/120mg' },
    { label: '40/240mg', value: '40/240mg' },
    { label: '60/360mg', value: '60/360mg' },
    { label: '80/480mg', value: '80/480mg' },
    { label: 'Tab Advantec 16/12.5mg', value: 'Tab Advantec 16/12.5mg' },
    { label: 'Tab Advant 16mg', value: 'Tab Advant 16mg' },
    { label: 'Cap Joint free', value: 'Cap Joint free' },
    { label: 'Cap Pregabalin 75mg', value: 'Cap Pregabalin 75mg' },
    { label: 'Cap ENAT 400 iu', value: 'Cap ENAT 400 iu' },
    { label: 'Vitamin A 200', value: 'Vitamin A 200' },
    { label: '000 iu', value: '000 iu' },
    { label: 'Tab Diclofenac sodium or Potassium 50mg', value: 'Tab Diclofenac sodium or Potassium 50mg' },
    { label: 'Tab Meloxicam 7.5mg', value: 'Tab Meloxicam 7.5mg' },
    { label: '15mg', value: '15mg' },
    { label: 'Tab salbutamol 4mg', value: 'Tab salbutamol 4mg' },
    { label: 'Tab prednisone 5mg', value: 'Tab prednisone 5mg' },
    { label: 'Tab vildagliptin 50mg', value: 'Tab vildagliptin 50mg' },
    { label: 'Tab trivia 50mg', value: 'Tab trivia 50mg' },
    { label: 'Tab trivia met 50/1000mg', value: 'Tab trivia met 50/1000mg' },
    { label: 'Tab atenolol 25mg', value: 'Tab atenolol 25mg' },
    { label: '50mg', value: '50mg' },
    { label: 'Tab Artemisini Piperaquine', value: 'Tab Artemisini Piperaquine' },
    { label: 'Tab diazepam 5mg', value: 'Tab diazepam 5mg' },
    { label: 'Tab bromazepam 1.5mg', value: 'Tab bromazepam 1.5mg' },
    { label: '3mg', value: '3mg' },
    { label: 'Tab nitrazepam 5mg', value: 'Tab nitrazepam 5mg' },
    { label: 'inj. Diagram 10mg', value: 'inj. Diagram 10mg' },
    { label: 'inj fluphenazine 25mg', value: 'inj fluphenazine 25mg' },
    { label: 'iv. Ciprofloxacin 200mg', value: 'iv. Ciprofloxacin 200mg' },
    { label: 'Iv. Metronidazole 500mg', value: 'Iv. Metronidazole 500mg' },
    { label: 'Iv. Ceftriazone 1g', value: 'Iv. Ceftriazone 1g' },
    { label: 'im. Hyoscine 10mg', value: 'im. Hyoscine 10mg' },
    { label: 'im. Diclofenac 75mg', value: 'im. Diclofenac 75mg' },
    { label: 'Tab Tizanidine 2mg', value: 'Tab Tizanidine 2mg' },
    { label: 'Cap altho are', value: 'Cap altho are' },
    { label: 'Cap arthocare Forte', value: 'Cap arthocare Forte' },
    { label: 'Tab Irbessrtan 150mg', value: 'Tab Irbessrtan 150mg' },
    { label: '300mg', value: '300mg' },
    { label: 'Tab co- irbesartan 150/12.5mg', value: 'Tab co- irbesartan 150/12.5mg' },
    { label: '300/12.5mg', value: '300/12.5mg' },
    { label: 'Tab Vallarta 80mg', value: 'Tab Vallarta 80mg' },
    { label: '160mg', value: '160mg' },
    { label: 'Tab telmisartan 40mg', value: 'Tab telmisartan 40mg' },
    { label: '80mg', value: '80mg' },
    { label: 'Tab co-telmisartan 40/12.5mg', value: 'Tab co-telmisartan 40/12.5mg' },
    { label: 'Tab 80/12.5mg', value: 'Tab 80/12.5mg' },
    { label: 'Tab clopidogrel 75mg', value: 'Tab clopidogrel 75mg' },
    { label: 'Tab Lasix 40mg', value: 'Tab Lasix 40mg' },
    { label: 'Tab tenofovir 300mg', value: 'Tab tenofovir 300mg' },
    { label: 'Tab tegretol cr 200mg', value: 'Tab tegretol cr 200mg' },
    { label: '400mg', value: '400mg' },
    { label: 'Tab Epilim 200mg', value: 'Tab Epilim 200mg' },
    { label: '500mg', value: '500mg' },
    { label: 'syr Paracetamol', value: 'syr Paracetamol' },
    { label: 'syr Vitamin c', value: 'syr Vitamin c' },
    { label: 'syr Vitamin blood', value: 'syr Vitamin blood' },
    { label: 'syr piriton', value: 'syr piriton' },
    { label: 'Tab chlorpheniramine 4mg', value: 'Tab chlorpheniramine 4mg' },
    { label: 'Tab fansidar', value: 'Tab fansidar' },
    { label: 'Tab amodiaquine 200mg', value: 'Tab amodiaquine 200mg' },
    { label: 'Tab moduretic', value: 'Tab moduretic' },
    { label: 'Tab Hydrochlorthiazide 25mg', value: 'Tab Hydrochlorthiazide 25mg' },
    { label: 'Tab glimepiride 2mg', value: 'Tab glimepiride 2mg' },
    { label: 'Tab panfor 500mg', value: 'Tab panfor 500mg' },
    { label: '1g', value: '1g' },
    { label: 'Tab diamicron 60mg', value: 'Tab diamicron 60mg' },
    { label: 'inj. Novomix', value: 'inj. Novomix' },
    { label: 'inj. Lantus', value: 'inj. Lantus' },
    { label: 'inj. Tetanus toroidal', value: 'inj. Tetanus toroidal' },
    { label: 'inj. Anti rabies', value: 'inj. Anti rabies' },
    { label: 'gutter xalatan', value: 'gutter xalatan' },
    { label: 'gutter cosopt', value: 'gutter cosopt' },
    { label: 'gutter chloramphenicol', value: 'gutter chloramphenicol' },
    { label: 'gutt gentamicin', value: 'gutt gentamicin' },
    { label: 'gutt diclofenac', value: 'gutt diclofenac' },
    { label: 'gutt antallerge', value: 'gutt antallerge' },
    { label: 'gutt betadrone-N', value: 'gutt betadrone-N' },
    { label: 'gutt vistulent', value: 'gutt vistulent' },
    { label: 'Gutt brimonidine', value: 'Gutt brimonidine' },
    { label: 'Gutt ciprofloxacine', value: 'Gutt ciprofloxacine' },
    { label: 'Cap celecoxib 200mg', value: 'Cap celecoxib 200mg' },
    { label: 'Tab cefuroxime 500mg', value: 'Tab cefuroxime 500mg' },
    { label: 'Tab ciprofloxacine', value: 'Tab ciprofloxacine' },
    { label: 'gutt pilocarpine', value: 'gutt pilocarpine' },
    { label: 'gutt atropine', value: 'gutt atropine' },
    { label: 'gutt mydriacine', value: 'gutt mydriacine' },
    { label: 'gutt tropicamide', value: 'gutt tropicamide' },
    { label: 'inj lidocaine plain and with adrenalin', value: 'inj lidocaine plain and with adrenalin' },
    { label: 'Tab ofloxacine 200mg', value: 'Tab ofloxacine 200mg' },
    { label: '400mg', value: '400mg' },
    { label: 'Cap azithromycine 250mg', value: 'Cap azithromycine 250mg' },
    { label: '500mg', value: '500mg' },
    { label: 'Tab tavanic 500mg', value: 'Tab tavanic 500mg' },
    { label: 'Cap amoxicillin 250mg', value: 'Cap amoxicillin 250mg' },
    { label: 'syr co-amoxicillin 228mg/5ml', value: 'syr co-amoxicillin 228mg/5ml' },
    { label: 'syr co-amoxicillin 457mg', value: 'syr co-amoxicillin 457mg' },
    { label: 'syr Mist Mag', value: 'syr Mist Mag' },
    { label: 'syr cough', value: 'syr cough' },
    { label: 'calamine lotion', value: 'calamine lotion' },
    { label: 'benzoyl benzoate', value: 'benzoyl benzoate' },
    { label: 'Tab monteleukast 5mg', value: 'Tab monteleukast 5mg' },
    { label: '10mg', value: '10mg' },
    { label: 'seretide diskus', value: 'seretide diskus' },
    { label: 'ventolin inhaler', value: 'ventolin inhaler' },
    { label: 'Tab omeprazole 20mg', value: 'Tab omeprazole 20mg' },
    { label: 'Cap rabeprazole 20mg', value: 'Cap rabeprazole 20mg' },
    { label: 'salbutamol nebule 2.5mg', value: 'salbutamol nebule 2.5mg' },
    { label: '5mg', value: '5mg' },
    { label: 'Tab benzhexol 5mg', value: 'Tab benzhexol 5mg' },
    { label: 'Tab chlorpromazine 50mg', value: 'Tab chlorpromazine 50mg' },
    { label: '100mg', value: '100mg' },
    { label: 'Tab haloperidol 5mg', value: 'Tab haloperidol 5mg' },
    { label: 'inj. FLUpenthixol 40mg', value: 'inj. FLUpenthixol 40mg' },
    { label: 'inj alpa beta artheether 75mg', value: 'inj alpa beta artheether 75mg' },
    { label: '150mg', value: '150mg' },
    { label: 'inj. Arthemether 80mg', value: 'inj. Arthemether 80mg' },
    { label: 'Tab amlodipine 5mg', value: 'Tab amlodipine 5mg' },
    { label: 'inj omeprazole 40mg', value: 'inj omeprazole 40mg' },
    { label: 'inj rabeprazole 40mg', value: 'inj rabeprazole 40mg' },
    { label: 'candler pink', value: 'candler pink' },
    { label: 'green ', value: 'green ' },
    { label: 'blue', value: 'blue' },
    { label: 'crepe bandage', value: 'crepe bandage' },
    { label: 'gauze bandage', value: 'gauze bandage' },
    { label: 'Cap livolin', value: 'Cap livolin' },
    { label: 'Cap ferrotone', value: 'Cap ferrotone' },
    { label: 'iv giving set', value: 'iv giving set' },
    { label: 'ivf 0.9% normal saline', value: 'ivf 0.9% normal saline' },
    { label: 'ivf 5% dextrose saline', value: 'ivf 5% dextrose saline' },
    { label: 'ivf 5%dextrose', value: 'ivf 5%dextrose' },
    { label: 'Ivf ringers lactate', value: 'Ivf ringers lactate' },
    { label: 'ivf 10% dextrose ', value: 'ivf 10% dextrose ' },
    { label: 'gutt travatan', value: 'gutt travatan' },
    { label: 'gutt timolol', value: 'gutt timolol' },
    { label: 'gutt tears naturale', value: 'gutt tears naturale' },
    { label: 'surgical blade', value: 'surgical blade' },
    { label: 'nylon 2/0 suture', value: 'nylon 2/0 suture' },
    { label: 'chronic 2/0 suture', value: 'chronic 2/0 suture' },
    { label: 'Tab cefpodoxime 100mg', value: 'Tab cefpodoxime 100mg' },
    { label: '200mg', value: '200mg' },
    { label: 'syr orelox', value: 'syr orelox' },
    { label: 'Cap doxycycline 100mg', value: 'Cap doxycycline 100mg' },
    { label: 'Tab levofloxacin 500mg', value: 'Tab levofloxacin 500mg' },
    { label: 'Tab clarithromycin 250mg', value: 'Tab clarithromycin 250mg' },
    { label: '500mg', value: '500mg' },
    { label: 'surgical gloves', value: 'surgical gloves' },
    { label: 'latex gloves', value: 'latex gloves' },
    { label: 'Tab DF 118', value: 'Tab DF 118' },
    { label: 'inj pentazocine 30mg', value: 'inj pentazocine 30mg' },
    { label: 'syr ciklavit', value: 'syr ciklavit' },
    { label: 'Tab proguanil 100mg', value: 'Tab proguanil 100mg' },
    { label: 'mycoten vagina Tab or cream', value: 'mycoten vagina Tab or cream' },
    { label: 'mycoten topical cream', value: 'mycoten topical cream' },
    { label: 'typical cream', value: 'typical cream' },
    { label: 'tydisil cream', value: 'tydisil cream' },
    { label: 'Whitfield ointment', value: 'Whitfield ointment' },
    { label: 'betamethasone cram', value: 'betamethasone cram' },
    { label: 'hydrocortisone injection', value: 'hydrocortisone injection' },
    { label: 'hydrocortisone cream', value: 'hydrocortisone cream' },
    { label: 'triple action cream', value: 'triple action cream' },
    { label: 'dermazine cream', value: 'dermazine cream' },
    { label: 'sufratulle gauze', value: 'sufratulle gauze' },
    { label: 'plaster', value: 'plaster' },
    { label: 'iodine', value: 'iodine' },
    { label: 'methylated spirit', value: 'methylated spirit' },
    { label: 'Tab Loratadine 10mg', value: 'Tab Loratadine 10mg' },
]